import React from "react";
import TranslationGuidePage from "../../articles/translation-guide";
import { graphql } from "gatsby";

const LocalizedTranslationGuidePage = (props) => (
  <TranslationGuidePage {...props} />
);

export default LocalizedTranslationGuidePage;

export const query = graphql`
  query ($id: String) {
    locale(id: { eq: $id }) {
      translatorInviteLink
    }
    allLocale(sort: { fields: languageName }) {
      edges {
        node {
          fullLocaleCode
          translatorInviteLink
          languageName
          flagCode
        }
      }
    }
  }
`;
